import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { Image } from "~components/Image";

// import Lottie from "lottie-react";
// import auth from "~animations/lotties/tick.json" // TODO: replace with actual lottie file
import { AnimLayerAuthentication } from "~animations/__layers__/mock_ui_components/AnimLayerAuthentication";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import * as styles from "./styles.css";

import type { BankBackground } from "./styles.css";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface AnimMockUiAuthenticationProps {
  title: string;
  image: StoryblokFieldMedia;
  bank: BankBackground;
}

export function AnimMockUiAuthentication({
  title,
  image,
  bank,
  ...rest
}: AnimMockUiAuthenticationProps) {
  return (
    <AnimatedMockUiWrapper
      {...rest}
      alignItems="center"
      className={clsx(
        variantBackgroundColor.background_charcoal,
        styles.getBackground({ bank })
      )}
      position="relative"
    >
      <Image marginTop="phoneSpacing16" isolation="isolate" image={image} />
      <Box className={styles.authAnimationContainer}>
        <AnimLayerAuthentication title={title} />
      </Box>
    </AnimatedMockUiWrapper>
  );
}
