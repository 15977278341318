import React, { useContext } from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";
import { StoryblokRichText } from "~components/StoryblokRichText";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerTrueLayerConnection } from "~animations/__layers__/mock_ui_components/AnimLayerTrueLayerConnection";
import { AnimLayerUiMockCollapsible } from "~animations/__layers__/mock_ui_components/AnimLayerUiMockCollapsible";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { PageQueryContext } from "~context/PageQueryContext";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/types";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerUiMockCollapsibleProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiMockCollapsible";
import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export interface AnimMockUiConsentProps extends StoryblokBlok, BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  textComplete?: string;
  description?: StoryblokFieldRichText;
  textSuccess?: string;
  textWithdrawalComplete?: string;
  title?: string;
  amount?: string;
  amountTitle?: string;
  bankIcon?: StoryblokFieldMedia;
  disclaimer?: string;
  animLayerIconList: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerMockCollapsible: Array<
    StoryblokBlok & AnimLayerUiMockCollapsibleProps
  >;
  brandName?: string;
  bankName?: string;
}

export function AnimMockUiConsent({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerIconList: animLayerIconListBlokArray,
  animLayerMockCollapsible: animLayerMockCollapsibleBlokArray,
  title,
  amount,
  amountTitle,
  bankIcon,
  description,
  disclaimer,
  brandName,
  bankName,
  ...rest
}: AnimMockUiConsentProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerIconList] = animLayerIconListBlokArray || [];
  const [animLayerMockCollapsible] = animLayerMockCollapsibleBlokArray || [];

  return (
    <AnimatedMockUiWrapper opacity="1" {...rest}>
      <AnimatedElement opacity="1" animationLevel="secondary" zIndex="2">
        {title && <AnimLayerUiTopNav title={title} />}
        <Box display="flex" flexDirection="column" gap="phoneGuttering1">
          <Box display="flex" flexDirection="column" gap="phoneSpacing1.5">
            {description && (
              <StoryblokRichText
                textAppearance="phone_text_sm"
                text={description}
                marginX="phoneSpacing2"
              />
            )}
            <Box
              display="flex"
              flexDirection="column"
              gap="phoneGutteringSm"
              marginX="phoneSpacing2"
            >
              {amountTitle && (
                <Box
                  display="flex"
                  border="extraLowContrast"
                  borderRadius="sm"
                  className={variantBackgroundColor.background_white}
                  paddingX="phoneSpacing2"
                  paddingY="phoneSpacing1"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {amountTitle && (
                    <Box
                      textAppearance="phone_text_sm"
                      color="text_lowContrast"
                    >
                      {amountTitle}
                    </Box>
                  )}

                  {amount && (
                    <Box textAppearance="phone_text_sm" fontWeight="semibold">
                      {formatCurrencyString({
                        amount: parseFloat(amount),
                        lang,
                      })}
                    </Box>
                  )}
                </Box>
              )}

              <AnimLayerTrueLayerConnection
                brandName={brandName}
                bankName={bankName}
                image={bankIcon}
              />

              {animLayerMockCollapsible && (
                <AnimLayerUiMockCollapsible {...animLayerMockCollapsible} />
              )}

              {animLayerIconList && (
                <AnimLayerIconList
                  padding="phoneSpacing1"
                  borderRadius="sm"
                  isArrowVisible={false}
                  className={variantBackgroundColor.background_sand}
                  {...animLayerIconList}
                />
              )}
            </Box>
          </Box>
          <Box>
            {disclaimer && (
              <Box
                marginX="phoneSpacing2"
                __fontSize={10}
                color="text_lowContrast"
                marginBottom="phoneSpacing3"
              >
                {disclaimer}
              </Box>
            )}

            {animLayerFakeButton && (
              <AnimLayerFakeButton
                variant="branded"
                marginBottom="phoneSpacing3"
                size="md"
                marginX="phoneSpacing2"
                {...animLayerFakeButton}
              />
            )}
          </Box>
        </Box>
      </AnimatedElement>
    </AnimatedMockUiWrapper>
  );
}
