import React from "react";

import * as styles from "./styles.css";

export interface MockUserAvatarProps {
  backgroundColor: string;
}

export function MockUserAvatar({ backgroundColor }: MockUserAvatarProps) {
  return (
    <div className={styles.mockUserAvatar} style={{ backgroundColor }}>
      SW
    </div>
  );
}
