import { AnimIndustryIGaming } from "~animations/industries/AnimIndustryIGaming";

import { AnimMockUiAccountAggregation } from "./AnimMockUiAccountAggregation";
import { AnimMockUiAccountOverview } from "./AnimMockUiAccountOverview";
import { AnimMockUiAuthentication } from "./AnimMockUiAuthentication";
import { AnimMockUiBankSelection } from "./AnimMockUiBankSelection";
import { AnimMockUiConsent } from "./AnimMockUiConsent";
import { AnimMockUiDeposit } from "./AnimMockUiDeposit";
import { AnimMockUiEcommerce1 } from "./AnimMockUiEcommerce1";
import { AnimMockUiEcommerce2 } from "./AnimMockUiEcommerce2";
import { AnimMockUiEcommerceRefund1 } from "./AnimMockUiEcommerceRefund1";
import { AnimMockUiEcommerceRefund2 } from "./AnimMockUiEcommerceRefund2";
import { AnimMockUiLoading } from "./AnimMockUiLoading";
import { AnimMockUiSignup2 } from "./AnimMockUiSignup2";
import { AnimMockUiSuccess } from "./AnimMockUiSuccess";
import { AnimMockUiVerification1 } from "./AnimMockUiVerification1";
import { AnimMockUiVrp1 } from "./AnimMockUiVrp1";
import { AnimMockUiVrp2 } from "./AnimMockUiVrp2";
import { AnimMockUiWithdrawal } from "./AnimMockUiWithdrawal";

export const MOCK_UI_SCREENS = {
  AnimIndustryIGaming,
  AnimMockUiAccountAggregation,
  AnimMockUiAccountOverview,
  AnimMockUiAuthentication,
  AnimMockUiDeposit,
  AnimMockUiEcommerce1,
  AnimMockUiEcommerce2,
  AnimMockUiEcommerceRefund1,
  AnimMockUiEcommerceRefund2,
  AnimMockUiLoading,
  AnimMockUiSignup2,
  AnimMockUiSuccess,
  AnimMockUiVerification1,
  AnimMockUiVrp1,
  AnimMockUiVrp2,
  AnimMockUiWithdrawal,
} as const;

export const MOCK_UI_DIALOGS = {
  AnimMockUiBankSelection,
  AnimMockUiConsent,
} as const;
