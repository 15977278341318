import React from "react";

import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerBalanceCardItem } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import { type AnimLayerTransactionListProps } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";
import { type AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import { AnimLayerPhoneBgTransactionList } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgTransactionList";
import { AnimMockUiAccountOverview } from "~animations/mock_ui/AnimMockUiAccountOverview";

import { THEME_CLASS_MAP } from "~types/fakeBrands.types";

import type { AnimatedHeroIllustration } from "../types";
import type { AnimLayerBalanceCardListProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardList";
import type { AnimLayerPhoneBgTransactionListProps } from "~animations/__layers__/phone_backgrounds/AnimLayerPhoneBgTransactionList";
import type { BoxProps } from "~components/Box";
import type { FakeBrands } from "~types/fakeBrands.types";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimHeroPayoutsProps extends AnimatedHeroIllustration {
  animLayerBalanceCardItem?: Array<
    StoryblokBlok & AnimLayerBalanceCardListProps
  >;
  animLayerPhoneBg?: Array<
    StoryblokBlok & AnimLayerPhoneBgTransactionListProps
  >;
  animLayerUiAccountInfoCard?: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
  animLayerTransactionList?: Array<
    StoryblokBlok & AnimLayerTransactionListProps
  >;
  fakeBrand?: FakeBrands;
}

export function AnimHeroPayouts({
  animLayerBalanceCardItem: animLayerBalanceCardItemBlokArray,
  animLayerPhoneBg: animLayerPhoneBgArray,
  animLayerUiAccountInfoCard: animLayerUiAccountInfoCardArray,
  animLayerTransactionList: animLayerTransactionListArray,
  fakeBrand = "default",
  ...rest
}: AnimHeroPayoutsProps) {
  const [animLayerBalanceCardItem] = animLayerBalanceCardItemBlokArray || [];
  const [animLayerPhoneBg] = animLayerPhoneBgArray || [];
  const [animLayerUiAccountInfoCard] = animLayerUiAccountInfoCardArray || [];
  const [animLayerTransactionList] = animLayerTransactionListArray || [];
  const fakeBrandTheme = THEME_CLASS_MAP[fakeBrand];

  const infoCardProps: BoxProps = {
    className: variantBackgroundColor.background_white,
    borderRadius: "md",
    width: "auto",
    marginX: "spacing14",
    zIndex: "2",
  };

  const transactionListProps: Partial<AnimLayerTransactionListProps> = {
    borderRadius: "md",
    inset: "0",
    marginX: "auto",
    maxWidth: "gridSpan3",
    amountsColor: "text_highContrast",
  };

  return (
    <Box
      {...rest}
      className={fakeBrandTheme}
      position="relative"
      width="100%"
      height="100%"
    >
      <ShowChildren when={animLayerPhoneBg}>
        <AnimLayerPhoneBgTransactionList
          inset="0"
          marginX="auto"
          maxWidth="gridSpan3"
          paddingTop="spacing14"
          position="absolute"
          __top={vars.spacing.spacing16}
          {...animLayerPhoneBg!}
        />
      </ShowChildren>

      <ShowChildren when={animLayerBalanceCardItem}>
        <AnimLayerBalanceCardItem
          inset="0"
          marginX="auto"
          position="absolute"
          __maxWidth={calc.subtract(
            vars.gridSpan.gridSpan3,
            vars.spacing.spacing2
          )}
          __top={vars.spacing.spacing12}
          {...animLayerBalanceCardItem!}
        />
      </ShowChildren>

      <ShowChildren
        when={animLayerUiAccountInfoCard || animLayerTransactionList}
      >
        <AnimMockUiAccountOverview
          inset="0"
          marginX="auto"
          position="absolute"
          top="spacing12"
          animLayerTransactionList={animLayerTransactionListArray!}
          infoCardProps={infoCardProps}
          transactionListProps={transactionListProps}
          transactionTransparentBg
          animLayerUiAccountInfoCard={animLayerUiAccountInfoCardArray!}
        />
      </ShowChildren>
    </Box>
  );
}
