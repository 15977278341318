import React from "react";

import { faSearch } from "@fortawesome/sharp-regular-svg-icons/faSearch";

import { type BoxProps } from "~components/Box";
import { StoryblokRichText } from "~components/StoryblokRichText";

import { AnimLayerBankSelectionList } from "~animations/__layers__/mock_ui_components/AnimLayerBankSelectionList";
import { AnimLayerInput } from "~animations/__layers__/mock_ui_components/AnimLayerFakeInput";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";
// import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerBankSelectionListProps } from "~animations/__layers__/mock_ui_components/AnimLayerBankSelectionList";
import type { AnimLayerEcommerceItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerInputProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeInput";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiBankSelectionProps extends StoryblokBlok, BoxProps {
  title?: string;
  text?: string;
  animLayerEcommerceItems: Array<StoryblokBlok & AnimLayerEcommerceItemsProps>;
  animLayerSearchBar: Array<StoryblokBlok & AnimLayerInputProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
  animLayerPaymentMethods: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerBankSelection: Array<
    StoryblokBlok & AnimLayerBankSelectionListProps
  >;
  brandLogo?: string; // TODO: automatic based on brand selected in Stepper
}

export function AnimMockUiBankSelection({
  animLayerSearchBar: animLayerInputBlokArray,
  animLayerBankSelection: animLayerBankSelectionBlokArray,
  title,
  text,
  // brandLogo, // temp
  ...rest
}: AnimMockUiBankSelectionProps) {
  const [animLayerInput] = animLayerInputBlokArray || [];
  const [animLayerBankSelection] = animLayerBankSelectionBlokArray || [];

  return (
    <AnimatedMockUiWrapper opacity="1" {...rest}>
      <AnimatedElement animationLevel="secondary" zIndex="2" opacity="1">
        {title && (
          <AnimLayerUiTopNav title={title} paddingTop="phoneSpacing2" />
        )}
        {text && (
          <StoryblokRichText
            textAppearance="phone_text_sm"
            text={text}
            marginX="phoneSpacing2"
            marginBottom="phoneSpacing1.5"
          />
        )}
        {animLayerInput && (
          <AnimLayerInput
            icon={faSearch}
            marginX="phoneSpacing2"
            {...animLayerInput}
          />
        )}
        {animLayerBankSelection && (
          <AnimLayerBankSelectionList {...animLayerBankSelection} />
        )}
      </AnimatedElement>
    </AnimatedMockUiWrapper>
  );
}
