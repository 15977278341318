import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { type BoxProps } from "~components/Box";

import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerEcommerceItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiEcommerce2Props extends StoryblokBlok, BoxProps {
  title?: string;
  animLayerEcommerceItems: Array<StoryblokBlok & AnimLayerEcommerceItemsProps>;
  animLayerInfoDialog: Array<StoryblokBlok & AnimLayerInfoDialogProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
  animLayerPaymentMethods: Array<StoryblokBlok & AnimLayerIconListProps>;
}

export function AnimMockUiEcommerce2({
  animLayerInfoDialog: animLayerInfoDialogBlokArray,
  animLayerPaymentMethods: animLayerPaymentMethodsBlokArray,
  title,
  ...rest
}: AnimMockUiEcommerce2Props) {
  const [animLayerInfoDialog] = animLayerInfoDialogBlokArray || [];
  const [animLayerPaymentMethods] = animLayerPaymentMethodsBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}

      {animLayerInfoDialog && (
        <AnimLayerInfoDialog
          marginX="phoneSpacing2"
          className={variantBackgroundColor.background_sand}
          padding="phoneSpacing2" // <- override component's default padding
          {...animLayerInfoDialog}
        />
      )}

      {animLayerPaymentMethods && (
        <AnimLayerIconList
          appearance="border"
          marginX="phoneSpacing2"
          {...animLayerPaymentMethods}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
