import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box, type BoxProps } from "~components/Box";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/types";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiVrp2Props extends StoryblokBlok, BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerInfoDialog: Array<StoryblokBlok & AnimLayerInfoDialogProps>;
  animLayerPaymentAmountOptions: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerPaymentDate: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerPaymentMethods: Array<StoryblokBlok & AnimLayerIconListProps>;
  textComplete?: string;
  textSuccess?: string;
  textWithdrawalComplete?: string;
  title?: string;
  isCurrent?: boolean;
}

export function AnimMockUiVrp2({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerInfoDialog: animLayerInfoDialogBlokArray,
  animLayerPaymentAmountOptions: animLayerPaymentAmountOptionsBlokArray,
  animLayerPaymentDate: animLayerPaymentDateBlokArray,
  animLayerPaymentMethods: animLayerPaymentMethodsBlokArray,
  title,
  isCurrent: _isCurrent, // not used in this component
  ...rest
}: AnimMockUiVrp2Props) {
  /** @todo: Fix this unreadable mess */
  const [animLayerInfoDialog] = animLayerInfoDialogBlokArray || [];
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerPaymentMethods] = animLayerPaymentMethodsBlokArray || [];
  const [animLayerPaymentAmountOptions] =
    animLayerPaymentAmountOptionsBlokArray || [];
  const [animLayerPaymentDate] = animLayerPaymentDateBlokArray || [];

  return (
    <AnimatedMockUiWrapper rowGap="phoneSpacing4" {...rest}>
      {title && (
        <AnimLayerUiTopNav
          paddingTop="phoneSpacing4"
          paddingBottom="none"
          iconLeft="faChevronLeft"
          title={title}
        />
      )}

      <Box display="flex" flexDirection="column" gap="phoneSpacing1">
        {animLayerInfoDialog && (
          <AnimLayerInfoDialog
            className={variantBackgroundColor.background_mockUiCard}
            isRadioButtonVisible
            marginX="phoneSpacing2"
            paddingX="phoneSpacing2"
            paddingY="phoneSpacing2"
            borderRadius="md"
            tagVariant="solid"
            bankLogos
            bankLogosNumber={4}
            {...animLayerInfoDialog}
          />
        )}

        {animLayerPaymentMethods && (
          <AnimLayerIconList
            appearance="default"
            className={variantBackgroundColor.background_mockUiCard}
            marginX="phoneSpacing2"
            borderRadius="md"
            {...animLayerPaymentMethods}
          />
        )}
      </Box>

      {animLayerPaymentAmountOptions && (
        <AnimLayerIconList
          appearance="default"
          borderRadius="md"
          marginX="phoneSpacing2"
          {...animLayerPaymentAmountOptions}
        />
      )}

      {animLayerPaymentDate && (
        <AnimLayerIconList
          appearance="default"
          borderRadius="md"
          marginX="phoneSpacing2"
          {...animLayerPaymentDate}
        />
      )}

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          variant="paybybank"
          payByBank
          bankLogos
          marginTop="auto"
          marginBottom="phoneSpacing4"
          size="md"
          marginX="phoneSpacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
