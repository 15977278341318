import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { faCircleCheck } from "@fortawesome/sharp-solid-svg-icons/faCircleCheck";
import { useLottie } from "lottie-react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiCenteredText } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";
// import intersectionLoader from "~animations/lotties/loader_crop.json";
import intersectionSuccess from "~animations/lotties/tick.json";

import { PageQueryContext } from "~context/PageQueryContext";

import * as styles from "./styles.css";

import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/types";
import type { AnimLayerUiCenteredTextProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

interface PaymentSuccessDetail extends StoryblokBlok, BoxProps {
  textLeft: string;
  textRight: string;
}
export interface AnimMockUiSuccessProps extends StoryblokBlok, BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerUiCenteredText: Array<StoryblokBlok & AnimLayerUiCenteredTextProps>;
  successIcon?: "intersection" | "tick";
  details: Array<PaymentSuccessDetail>;
  loading?: Array<StoryblokBlok & AnimLayerUiCenteredTextProps>;
  isCurrent: boolean;
}

export function AnimMockUiSuccess({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerUiCenteredText: animLayerUiCenteredTextBlokArray,
  loading: loadingCenteredTextArray,
  details,
  isCurrent: isCurrentProp,
  successIcon,
  ...rest
}: AnimMockUiSuccessProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerUiCenteredText] = animLayerUiCenteredTextBlokArray || [];
  const [loadingCenteredText] = loadingCenteredTextArray || [];

  const nonNumericCharacters = /[^\d.-]/g;

  /**
   * Handle loading and success mock animations
   */
  const [isCurrent, setIsCurrent] = useState(false);
  const [isLoading, setLoading] = useState(successIcon === "intersection");
  const [loadingComplete, setLoadingComplete] = useState(
    successIcon !== "intersection"
  );
  //  To be properly reimplemented in the future
  // const IntersectionLoader = useLottie(
  //   { animationData: intersectionLoader, loop: true, autoplay: false },
  //   {
  //     display: isLoading ? "block" : "none",
  //   }
  // );
  const IntersectionSuccess = useLottie(
    { animationData: intersectionSuccess, loop: false, autoplay: false },
    {
      display: isLoading ? "none" : "block",
    }
  );
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const completeMockLoading = useCallback(() => {
    // IntersectionLoader.stop();
    setLoading(false);
    setLoadingComplete(true);
    IntersectionSuccess.play();
  }, [
    // IntersectionLoader,
    IntersectionSuccess,
  ]);
  const startMockLoading = useCallback(
    ({ seconds }: { seconds: number }) => {
      setLoading(true);
      // IntersectionLoader.setSpeed(1.5);  // To be properly reimplemented in the future
      // IntersectionLoader.play();

      if (timeoutId.current) clearTimeout(timeoutId.current);

      timeoutId.current = setTimeout(() => {
        completeMockLoading();
      }, seconds * 1_000);
    },
    [
      // IntersectionLoader,  // To be properly reimplemented in the future
      completeMockLoading,
    ]
  );
  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, []);
  useEffect(() => {
    if (isCurrentProp !== isCurrent) {
      setIsCurrent(isCurrentProp);
    }
  }, [isCurrentProp, isCurrent]);
  useEffect(() => {
    if (isCurrent && !loadingComplete && successIcon === "intersection") {
      startMockLoading({ seconds: 0 }); // To be properly reimplemented in the future
    }

    if (!isCurrent && loadingComplete && successIcon === "intersection") {
      // IntersectionLoader.stop();  // To be properly reimplemented in the future
      IntersectionSuccess.stop();
      setLoadingComplete(false);
    }
  }, [
    isCurrent,
    startMockLoading,
    IntersectionSuccess,
    // IntersectionLoader, // To be properly reimplemented in the future
    // isLoading,  // To be properly reimplemented in the future
    loadingComplete,
    successIcon,
  ]);

  return (
    <AnimatedMockUiWrapper {...rest}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        className={variantBackgroundColor.background_mockUiCard}
      >
        <Box width="33%" marginX="auto">
          <ShowChildren when={successIcon === "intersection"}>
            {/*  To be properly reimplement in the future
            {IntersectionLoader.View}
             */}
            {IntersectionSuccess.View}
          </ShowChildren>
          <ShowChildren when={successIcon === "tick"}>
            <IconFontAwesome
              icon={faCircleCheck as IconDefinition}
              className={styles.successIconTick}
            />
          </ShowChildren>
        </Box>
        <AnimLayerUiCenteredText
          marginX="phoneSpacing2"
          {...(isLoading ? loadingCenteredText : animLayerUiCenteredText)}
        />

        <ShowChildren when={details.length > 1}>
          <Box
            className={variantBackgroundColor.background_white}
            marginX="phoneSpacing2"
            paddingX="phoneSpacing2"
            paddingY="phoneSpacing0"
            marginTop="phoneSpacing3"
            borderRadius="md"
          >
            {details?.map((detail, i) => {
              const shouldHaveBorder =
                details.length > 1 && i !== details.length - 1;

              return (
                <StoryblokEditable key={detail.textLeft} {...detail}>
                  <Box
                    data-motion={ANIMATED.secondary}
                    display="flex"
                    alignItems="center"
                    textAppearance="phone_text_sm"
                    paddingY="phoneSpacing1"
                    justifyContent="space-between"
                    key={detail.textLeft}
                    {...{
                      borderBottom: shouldHaveBorder
                        ? "extraLowContrast"
                        : "none",
                    }}
                  >
                    <Box color="text_lowContrast">{detail.textLeft}</Box>
                    <Box fontWeight="semibold" color="text_lowContrast">
                      {detail.textRight?.includes("£")
                        ? formatCurrencyString({
                            amount: parseFloat(
                              /** strip out non-numeric characters */
                              detail.textRight.replace(nonNumericCharacters, "")
                            ),
                            lang,
                          })
                        : detail.textRight}
                    </Box>
                  </Box>
                </StoryblokEditable>
              );
            })}
          </Box>
        </ShowChildren>

        {animLayerFakeButton && (
          <AnimLayerFakeButton
            variant="branded"
            ctaIcon="faArrowRightLong"
            marginY="phoneSpacing4"
            size="md"
            marginX="phoneSpacing2"
            {...animLayerFakeButton}
          />
        )}
      </Box>
    </AnimatedMockUiWrapper>
  );
}
