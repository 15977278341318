import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimLayerUiCenteredText } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerEcommerceItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { AnimLayerUiCenteredTextProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiSignup2Props extends StoryblokBlok, BoxProps {
  title?: string;
  animLayerEcommerceItems: Array<StoryblokBlok & AnimLayerEcommerceItemsProps>;
  animLayerInfoDialog: Array<StoryblokBlok & AnimLayerInfoDialogProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
  animLayerIconList: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerUiCenteredText: Array<StoryblokBlok & AnimLayerUiCenteredTextProps>;
}

export function AnimMockUiSignup2({
  animLayerInfoDialog: animLayerInfoDialogBlokArray,
  animLayerIconList: animLayerIconListBlokArray,
  animLayerUiCenteredText: animLayerUiCenteredTextBlokArray,
  title,
  ...rest
}: AnimMockUiSignup2Props) {
  const [animLayerInfoDialog] = animLayerInfoDialogBlokArray || [];
  const [animLayerIconList] = animLayerIconListBlokArray || [];
  const [animLayerUiCenteredText] = animLayerUiCenteredTextBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      <ShowChildren when={title}>
        <AnimLayerUiTopNav
          paddingTop="phoneSpacing4"
          title={title!}
          iconLeft="faChevronLeft"
        />
      </ShowChildren>

      <ShowChildren when={animLayerInfoDialog}>
        <AnimLayerInfoDialog
          bankLogos
          bankLogosPosition="right"
          marginX="phoneSpacing2"
          className={variantBackgroundColor.background_mockUiCard}
          padding="phoneSpacing2" // <- override component's default padding
          fakeInput
          fakeButton={{
            variant: "primary",
            maxHeight: "phoneSpacing5",
            ctaIcon: "faArrowRightLong",
            leftCtaIcon: "faBolt",
            justifyContent: "space-between",
            size: "sm",
          }}
          {...animLayerInfoDialog}
        />
      </ShowChildren>

      <ShowChildren when={animLayerIconList}>
        <AnimLayerIconList marginX="phoneSpacing2" {...animLayerIconList!} />
      </ShowChildren>

      <ShowChildren when={animLayerUiCenteredText}>
        <AnimLayerUiCenteredText
          marginTop="auto"
          marginBottom="phoneSpacing4"
          marginX="phoneSpacing2"
          detailsAppearance="phone_text_sm"
          {...animLayerUiCenteredText}
        />
      </ShowChildren>
    </AnimatedMockUiWrapper>
  );
}
