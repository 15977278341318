import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box, type BoxProps } from "~components/Box";

import { AnimLayerEcommerceItems } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import { AnimLayerEcommerceOrderDetails } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { DEFAULT_FAKE_BUTTON } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/default_fake_button";
import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { THEME_CLASS_MAP } from "~types/fakeBrands.types";

import * as styles from "./styles.css";

import type { AnimLayerEcommerceItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/types";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { FakeBrandLogo, FakeBrands } from "~types/fakeBrands.types";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiEcommerce1Props
  extends StoryblokBlok,
    Omit<BoxProps, "lang"> {
  title?: string;
  brandLogo?: FakeBrandLogo;
  animLayerEcommerceItems: Array<StoryblokBlok & AnimLayerEcommerceItemsProps>;
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
  animLayerInfoDialog?: Array<StoryblokBlok & AnimLayerInfoDialogProps>;
  animLayerPaymentMethods?: Array<StoryblokBlok & AnimLayerIconListProps>;
  fakeBrand?: FakeBrands;
}

export function AnimMockUiEcommerce1({
  animLayerEcommerceItems: animLayerEcommerceItemsBlokArray,
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerEcommerceOrderDetails: animLayerEcommerceOrderDetailsBlokArray,
  animLayerInfoDialog: animLayerInfoDialogBlokArray,
  animLayerPaymentMethods: animLayerPaymentMethodsBlokArray,
  brandLogo,
  fakeBrand,
  ...rest
}: AnimMockUiEcommerce1Props) {
  const [animLayerEcommerceItems] = animLayerEcommerceItemsBlokArray || [];
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerEcommerceOrderDetails] =
    animLayerEcommerceOrderDetailsBlokArray || [];
  const [animLayerInfoDialog] = animLayerInfoDialogBlokArray || [];
  const [animLayerPaymentMethods] = animLayerPaymentMethodsBlokArray || [];
  const fakeBrandTheme = fakeBrand ? THEME_CLASS_MAP[fakeBrand] : "";

  return (
    <AnimatedMockUiWrapper className={fakeBrandTheme} {...rest}>
      <AnimLayerUiTopNav
        brandLogo={brandLogo}
        iconLeft="faChevronLeft"
        iconRight="faBagShopping"
        paddingTop="phoneSpacing4"
      />

      {animLayerEcommerceItems && (
        <AnimLayerEcommerceItems
          marginX="phoneSpacing2"
          {...animLayerEcommerceItems}
        />
      )}

      {animLayerEcommerceOrderDetails && (
        <AnimLayerEcommerceOrderDetails
          marginX="phoneSpacing2"
          borderBottom="extraLowContrast"
          paddingBottom="phoneSpacing2"
          {...animLayerEcommerceOrderDetails}
        />
      )}

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="phoneSpacing4"
          size="md"
          marginX="phoneSpacing2"
          {...animLayerFakeButton}
        />
      )}
      <Box display="flex" flexDirection="column" gap="phoneGutteringSm">
        {animLayerInfoDialog && (
          <AnimLayerInfoDialog
            marginX="phoneSpacing2"
            className={variantBackgroundColor.background_white}
            border="default"
            boxShadow="light"
            padding="phoneSpacing2" // <- override component's default padding
            fakeButton={DEFAULT_FAKE_BUTTON}
            {...animLayerInfoDialog}
          />
        )}

        {animLayerPaymentMethods && (
          <AnimLayerIconList
            appearance="outline"
            marginX="phoneSpacing2"
            isArrowVisible={false}
            gap="phoneGutteringSm"
            display="flex"
            flexDirection="column"
            className={clsx(
              variantBackgroundColor.background_white,
              styles.paymentMethods
            )}
            {...animLayerPaymentMethods}
          />
        )}
      </Box>
    </AnimatedMockUiWrapper>
  );
}
