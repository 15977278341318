import React from "react";

import clsx from "clsx";

import { commonStackedGrid } from "~styles/common/common.stacked-grid.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { Video } from "~components/Video";

import { AnimLayerAlert } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import { AnimLayerNotification } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";

import { useAnimationsHero } from "./_useAnimationsHero";
import * as styles from "./styles.css";

import type { AnimatedHeroIllustration } from "../types";
import type { JSXElementConstructor, ReactElement } from "react";
import type { BoxProps } from "~components/Box";
import type { varsClipPathInset } from "~styles/vars/varsClipPathInset.css";

type AnimatedHeroChildren = ReactElement<
  unknown,
  string | JSXElementConstructor<unknown>
>;

type AnimatedHeroWrapperProps = BoxProps &
  AnimatedHeroIllustration & {
    children:
      | AnimatedHeroChildren
      | undefined
      | Array<AnimatedHeroChildren | undefined>;
    clipPath?: keyof typeof varsClipPathInset;
  };

export function AnimatedHeroWrapper({
  animLayerAlert: animLayerBlokArray,
  animLayerNotification: animLayerNotificationBlokArray,
  children,
  clipPath,
  video: videoBlokArray,
  ...rest
}: AnimatedHeroWrapperProps) {
  const refContainer = useAnimationsHero({ clipPath });

  const [video] = videoBlokArray || [];
  const [animLayerAlert] = animLayerBlokArray || [];
  const [animLayerNotification] = animLayerNotificationBlokArray || [];

  return (
    <Box ref={refContainer} position="relative" {...rest}>
      <Box
        aspectRatio="square"
        className={clsx(commonStackedGrid)}
        data-motion={ANIMATED.mask}
        display="grid"
        justifyItems="center"
        alignItems="center"
      >
        {video && (
          <Video
            isHiddenOnMobile
            aspectRatio="square"
            height="100%"
            objectFit="cover"
            position="absolute"
            width="auto"
            zIndex="-1"
            {...video}
          />
        )}

        {React.Children.map(children, (child) => {
          return (
            child &&
            React.cloneElement(child, {
              "data-motion": ANIMATED.primary,
              "data-testid": "wrapper-bg",
            })
          );
        })}
      </Box>

      {animLayerNotification && (
        <AnimLayerNotification
          className={styles.notificationStyle}
          {...animLayerNotification}
        />
      )}

      {animLayerAlert && (
        <AnimLayerAlert className={styles.alertStyle} {...animLayerAlert} />
      )}
    </Box>
  );
}
