import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { AnimLayerCreditCardInfo } from "~animations/__layers__/mock_ui_components/AnimLayerCreditCardInfo";
import { AnimLayerPaymentDue } from "~animations/__layers__/mock_ui_components/AnimLayerPaymentDue";
import { AnimLayerUiBalanceWithGraph } from "~animations/__layers__/mock_ui_components/AnimLayerUiBalanceWithGraph";
import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { type FakeBrandLogo } from "~types/fakeBrands.types";

import * as styles from "./styles.css";

import type { AnimLayerPaymentDueProps } from "~animations/__layers__/mock_ui_components/AnimLayerPaymentDue";
import type { AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiVrp1Props extends StoryblokBlok, BoxProps {
  textWithdrawalComplete?: string;
  textSuccess?: string;
  textComplete?: string;
  title?: string;
  brandLogo?: FakeBrandLogo;
  animLayerUiPaymentDue: Array<StoryblokBlok & AnimLayerPaymentDueProps>;
  animLayerUiBalanceWithGraph: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
}

export function AnimMockUiVrp1({
  animLayerUiPaymentDue: animLayerUiPaymentDueBlokArray,
  animLayerUiBalanceWithGraph: animLayerUiBalanceWithGraphBlokArray,
  title: _title,
  brandLogo,
  ...rest
}: AnimMockUiVrp1Props) {
  const [animLayerUiPaymentDue] = animLayerUiPaymentDueBlokArray || [];
  const [animLayerUiBalanceWithGraph] =
    animLayerUiBalanceWithGraphBlokArray || [];

  return (
    <AnimatedMockUiWrapper
      className={variantBackgroundColor.background_white}
      {...rest}
    >
      <AnimLayerUiTopNav
        brandLogo={brandLogo}
        iconRight="faUserCircle"
        paddingTop="phoneSpacing4"
      />

      {animLayerUiBalanceWithGraph && (
        <AnimLayerUiBalanceWithGraph
          width="100%"
          maxWidth="100%"
          {...animLayerUiBalanceWithGraph}
        />
      )}

      <AnimLayerCreditCardInfo marginX="phoneSpacing2" />

      {animLayerUiPaymentDue && (
        <AnimLayerPaymentDue
          marginX="phoneSpacing2"
          {...animLayerUiPaymentDue}
        />
      )}
      <AnimLayerUiBottomNav
        borderTop="extraLowContrast"
        className={styles.bottomNav}
        marginTop="auto"
      />
    </AnimatedMockUiWrapper>
  );
}
