import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";

import * as styles from "./styles.css";

export interface StepperProps {
  steps: number;
  activeStep: number;
}

// TODO: add customizable style if used anywhere else
export function VerificationStepper({ steps, activeStep }: StepperProps) {
  return (
    <Box
      className={styles.stepper}
      paddingX="phoneSpacing2"
      paddingTop="phoneSpacing5"
      paddingBottom="phoneSpacing2"
    >
      {[...Array(steps).keys()].map((s) => {
        return (
          <div
            className={clsx(
              styles.step,
              s + 1 === activeStep
                ? styles.stepVariants.active
                : styles.stepVariants.inactive
            )}
            key={`step-${s}`}
          >
            {s + 1}
          </div>
        );
      })}
    </Box>
  );
}
