import React, { useContext } from "react";

import { faMinus } from "@fortawesome/sharp-regular-svg-icons/faMinus";
import { faPlus } from "@fortawesome/sharp-regular-svg-icons/faPlus";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiPayoutMethod } from "~animations/__layers__/mock_ui_components/AnimLayerUiPayoutMethod";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { PageQueryContext } from "~context/PageQueryContext";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/types";
import type { AnimLayerUiPayoutMethodProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiPayoutMethod";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiWithdrawalProps extends BoxProps {
  title?: string;
  amount?: number;
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerPayoutMethod: Array<StoryblokBlok & AnimLayerUiPayoutMethodProps>;
}

export function AnimMockUiWithdrawal({
  title,
  amount,
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerPayoutMethod: animLayerPayoutMethodBlokArray,
  ...rest
}: AnimMockUiWithdrawalProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerPayoutMethod] = animLayerPayoutMethodBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}
      <Box
        display="flex"
        flexDirection="column"
        marginX="phoneSpacing2"
        padding="phoneSpacing2"
        gap="phoneGutteringMd"
        borderRadius="md"
        className={variantBackgroundColor.background_mockUiCard}
      >
        {amount && (
          <Box display="flex" justifyContent="center">
            <IconFontAwesome
              icon={faMinus}
              size="sm"
              padding="phoneSpacing1"
              width="icon_md"
              height="icon_md"
            />
            <Box marginX="auto" textAppearance="h5" fontWeight="semibold">
              {formatCurrencyString({
                amount,
                lang,
              })}
            </Box>
            <IconFontAwesome
              icon={faPlus}
              size="sm"
              padding="phoneSpacing1"
              width="icon_md"
              height="icon_md"
            />
          </Box>
        )}

        {animLayerPayoutMethod && (
          <AnimLayerUiPayoutMethod {...animLayerPayoutMethod} />
        )}
      </Box>

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          variant="paybybank"
          payByBank
          marginTop="auto"
          marginBottom="phoneSpacing4"
          size="sm"
          marginX="phoneSpacing2"
          borderRadius="pill"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
