import React from "react";

import { vars } from "~styles/themes/theme.css";

import { Box } from "~components/Box";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerTransactionList } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";
import { AnimLayerUiAccountInfoCard } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
// import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimLayerTransparentPhoneBg } from "~animations/__layers__/phone_backgrounds/AnimLayerTransparentPhoneBg";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { THEME_CLASS_MAP } from "~types/fakeBrands.types";

import type { AnimLayerTransactionListProps } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";
import type { AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import type { BoxProps } from "~components/Box";
import type { FakeBrandLogo, FakeBrands } from "~types/fakeBrands.types";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiAccountOverviewProps
  extends StoryblokBlok,
    Omit<BoxProps, "lang"> {
  textWithdrawalComplete?: string;
  textSuccess?: string;
  textComplete?: string;
  title?: string;
  brandLogo?: FakeBrandLogo;
  fakeBrand?: FakeBrands;
  animLayerTransactionList: Array<
    StoryblokBlok & AnimLayerTransactionListProps
  >;
  infoCardProps?: BoxProps;
  transactionListProps?: BoxProps;
  animLayerUiAccountInfoCard: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
  transactionTransparentBg?: boolean;
}

export function AnimMockUiAccountOverview({
  animLayerTransactionList: animLayerTransactionListBlokArray,
  infoCardProps,
  transactionListProps,
  transactionTransparentBg,
  animLayerUiAccountInfoCard: animLayerUiAccountInfoCardBlokArray,
  title,
  brandLogo,
  fakeBrand,
  ...rest
}: AnimMockUiAccountOverviewProps) {
  const [animLayerTransactionList] = animLayerTransactionListBlokArray || [];
  const [animLayerUiAccountInfoCard] =
    animLayerUiAccountInfoCardBlokArray || [];
  const fakeBrandTheme = fakeBrand ? THEME_CLASS_MAP[fakeBrand] : "";

  return (
    <AnimatedMockUiWrapper className={fakeBrandTheme} rowGap="none" {...rest}>
      {title && (
        <AnimLayerUiTopNav brandLogo={brandLogo} iconRight="faUserCircle" />
      )}
      <Box display="flex" flexDirection="column" gap="phoneGuttering1">
        <ShowChildren when={animLayerUiAccountInfoCard}>
          <AnimLayerUiAccountInfoCard
            marginX="phoneSpacing2"
            {...animLayerUiAccountInfoCard!}
            {...infoCardProps}
          />
        </ShowChildren>

        <ShowChildren
          when={!transactionTransparentBg && animLayerTransactionList}
        >
          <AnimLayerTransactionList
            {...animLayerTransactionList!}
            {...transactionListProps}
          />
        </ShowChildren>

        <ShowChildren
          when={transactionTransparentBg && animLayerTransactionList}
        >
          <AnimLayerTransparentPhoneBg
            paddingTop="spacing18"
            __marginTop={vars.spacing["-spacing40"]}
            {...transactionListProps}
          >
            <AnimLayerTransactionList
              {...animLayerTransactionList!}
              {...transactionListProps}
            />
          </AnimLayerTransparentPhoneBg>
        </ShowChildren>
      </Box>
      {/* <AnimLayerUiBottomNav marginTop="auto" /> */}
    </AnimatedMockUiWrapper>
  );
}
