import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import { variantBackgroundColorNew } from "~styles/common/variant.backgroundColor.css";
import { varsFakeBrand } from "~styles/themes/fakeBrands/theme_contract_fakeBrands.css";

import { Box } from "~components/Box";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";
import { VerificationStepper } from "~animations/mock_ui/AnimMockUiVerification1/VerificationStepper";

import {
  type FakeBrandLogo,
  type FakeBrands,
  THEME_CLASS_MAP,
} from "~types/fakeBrands.types";

import * as styles from "./styles.css";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton/types";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiVerification1Props extends StoryblokBlok, BoxProps {
  title?: string;
  description?: string;
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  brandLogo: FakeBrandLogo;
  fakeBrand?: FakeBrands;
}

export function AnimMockUiVerification1({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  description,
  title,
  brandLogo,
  fakeBrand,
  ...rest
}: AnimMockUiVerification1Props) {
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const fakeBrandTheme = fakeBrand ? THEME_CLASS_MAP[fakeBrand] : "";

  return (
    <AnimatedMockUiWrapper
      justifyContent="start"
      rowGap="none"
      className={fakeBrandTheme}
      {...rest}
    >
      <ShowChildren when={!!title || !!brandLogo}>
        <AnimLayerUiTopNav
          brandLogo={brandLogo}
          iconLeft="faChevronLeft"
          avatar
          avatarBgColor={varsFakeBrand.color.primary}
        />
      </ShowChildren>
      <VerificationStepper steps={3} activeStep={1} />
      <Box
        className={variantBackgroundColorNew.background_mockUiCard[0]}
        borderRadius="md"
        marginX="phoneSpacing2"
        paddingX="phoneSpacing2"
        paddingTop="phoneSpacing5"
        paddingBottom="phoneSpacing2"
      >
        <Box width="gridSpan1" marginBottom="phoneSpacing1" marginX="auto">
          <StaticImage
            alt="verification"
            src="../../../assets/png/verify_shield.png"
            placeholder="blurred"
            loading="lazy"
          />
        </Box>
        <ShowChildren when={!!title}>
          <Box
            textAlign="center"
            textAppearance="phone_text_lg"
            color="text_highContrast"
            fontWeight="semibold"
            marginBottom="phoneSpacing1"
          >
            {title}
          </Box>
        </ShowChildren>
        {description && (
          <Box
            textAlign="center"
            textAppearance="phone_text_md"
            color="text_highContrast"
          >
            {description}
          </Box>
        )}
        {animLayerFakeButton && (
          <AnimLayerFakeButton
            marginTop="phoneSpacing4"
            size="md"
            className={styles.fakeButton}
            leftCtaIcon="faLock"
            {...animLayerFakeButton}
          />
        )}
      </Box>
    </AnimatedMockUiWrapper>
  );
}
